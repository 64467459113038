import { useState } from "react";
import Passport from "../Fields/Passport.jsx";
import DateField from "../Fields/Datefield.jsx";
import NumberField from "../Fields/Numberfield.jsx";
import SelectCheckBoxes from "../Fields/SelectCheckBoxes.jsx";
import SelectField from "../Fields/SelectField.jsx";
import TextFieldAll from "../Fields/TextFieldAll.jsx";
import TextCheckBox from "../Fields/TextСheckBox.jsx";
import DynamicField from "../Fields/Dynamicfields.jsx";
import LocationComponent from "../Fields/Location.jsx";
import Address from "../Fields/Address.jsx";
import AddressCode from "../Fields/AddressCode.jsx";
import Phone from "../Fields/Phone.jsx";

const ConFieldInput = (props) => {
  const {
    id,
    label,
    typeInput,
    typeText,
    valueWidth,
    data,
    selectedId,
    defaultValue,
    innerFields,
    referenceValue,
    tooltip,
    inputMode,
    isChecked,
    isCheckedView,
    addButton,
    required,
    value,
    setValue,
    error,
    setError,
    block,
  } = props;

  const numberFormat = typeInput.includes("number") && typeInput.split("_")[1];

  const fieldProps = {
    id,
    label,
    typeInput,
    typeText,
    valueWidth,
    data,
    selectedId,
    defaultValue,
    innerFields,
    numberFormat,
    referenceValue,
    tooltip,
    inputMode,
    isChecked,
    isCheckedView,
    addButton,
    required,
    value: value[id],
    error: error[id],
    setValue,
    setError,
  };

  return typeInput === "passport" ? (
    <Passport key={typeInput} {...fieldProps} />
  ) : typeInput === "date" ? (
    <DateField key={typeInput} {...fieldProps} />
  ) : typeInput === "address" ? (
    <Address key={typeInput} {...fieldProps} />
  ) : typeInput.includes("number") ? (
    <NumberField key={typeInput} {...fieldProps} />
  ) : typeInput === "select-checkboxes" ? (
    <SelectCheckBoxes key={typeInput} {...fieldProps} />
  ) : typeInput === "select" ? (
    <SelectField key={typeInput} {...fieldProps} values={value} />
  ) : typeInput === "code" ? (
    <AddressCode key={typeInput} {...fieldProps} />
  ) : typeInput === "checkbox" ? (
    <TextCheckBox key={typeInput} {...fieldProps} />
  ) : typeInput === "phone" ? (
    <Phone key={typeInput} {...fieldProps} />
  ) : typeInput === "dynamic" ? (
    <DynamicField key={typeInput} {...fieldProps} />
  ) : typeInput === "location" ? (
    <LocationComponent key={typeInput} {...fieldProps} />
  ) : (
    <TextFieldAll key={typeInput} {...fieldProps} />
  );
};

export default ConFieldInput;
