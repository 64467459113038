import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import TypeSelect from "../components/TypeSelect/TypeSelect.js";
import ConHeader from "../components/ConHeader/ConHeader.js";
import ConPaper from "../components/ConPaper/ConPaper.js";
import { Contractors } from "../components/contractor.data.js";
import ConDataInput from "../components/ConDataInput/СonDataInput.js";
import {
  ThemeProvider,
  createTheme,
  Box,
  CssBaseline,
  GlobalStyles,
  Typography,
} from "@mui/material";
import generateDoc from "../service/generateDoc.js";
import ConDialogDoc from "../components/ConDialogDoc/ConDialogDoc.js";
import ModalDialog from "../components/ModalDialog/ModalDialog.js";
import checkUser from "../service/checkUser.js";
import getOrderData from "../service/getOrderData.js";

const HomePage = ({ setPaymentId, userData, setUserData }) => {
  const [selectedContract, setSelectedContract] = useState(""); // Состояние для значений выбора договора
  const [selectedContractData, setSelectedContractData] = useState(null);
  const [istg, setIsTg] = useState(false); // Состояние миниприложения в ТГ
  const [tg, setTg] = useState({}); // Состояние миниприложения в ТГ
  const [darkMode, setDarkMode] = useState(true); // Состояние для хранения текущей темы (светлая или тёмная)
  const [urlDoc, setUrlDoc] = useState("");
  const [orderId, setOrderId] = useState("");
  const [loading, setLoading] = useState(false);
  const [inputValues, setInputValues] = useState({}); // Состояние для хранения заполненных значений
  const [valuesError, setValuesError] = useState({});
  const [isType, setIsType] = useState(false);
  const [requestValues, setRequestValues] = useState({});
  const [isOrder, setIsOrder] = useState(true);

  const googleDocsUrl = urlDoc
    ? `https://docs.google.com/gview?url=${encodeURIComponent(urlDoc)}&embedded=true`
    : "";

  const getUser = async () => {
    await checkUser(setUserData);
  };

  useEffect(() => {
    if (window.Telegram && window.Telegram.WebApp) {
      const tg = window.Telegram.WebApp;
      setTg(tg);
      tg.ready();
      const initialTheme = tg.themeParams;
      if (!(initialTheme && Object.keys(initialTheme).length === 0)) {
        setIsTg(true);
        setDarkMode(tg.colorScheme === "dark" ? true : false);
      } else {
        const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
        setDarkMode(mediaQuery.matches);
        getUser();
      }
    }
    const params = new URLSearchParams(window.location.search);
    if (params.get("type")) {
      const paramsData = {
        typeContract: params.get("type"),
        landlord: params.get("landlord") && params.get("landlord") === "true" ? true : false,
        tenant: params.get("tenant") && params.get("tenant") === "true" ? true : false,
        chatId: params.get("chat_id"),
        orderId: params.get("order_id"),
        key: params.get("key"),
        change: params.get("change"),
        humanType:
          params.get("landlord") === "true"
            ? "landlord"
            : params.get("tenant") === "true"
            ? "tenant"
            : "all",
      };
      setRequestValues(paramsData);
      setSelectedContract(paramsData.typeContract);
      const data = Contractors.find((item) => item.id === parseInt(paramsData.typeContract));
      setSelectedContractData(data);
      setIsType(true);
      console.log(paramsData.change);
      if (paramsData.change) {
        async function getData() {
          await getOrderData(
            paramsData.key,
            paramsData.orderId,
            paramsData.humanType,
            setInputValues,
            setIsOrder
          );
        }
        getData();
      }
    }
    //setLoading(false);
  }, []);

  // Создание темы в зависимости от состояния darkMode
  const theme = createTheme({
    palette: {
      mode: darkMode ? "dark" : "light", // Выбор светлой или тёмной темы
    },
    spacing: 8,
  });

  const handleContractChange = (newContract) => {
    setInputValues({});
    setValuesError({});
    setSelectedContract(newContract);
    const data = Contractors.find((item) => item.id === newContract);
    setSelectedContractData(data);
  };

  const handleSubmitValues = async () => {
    // Отправляем значения для обработки
    const newErrors = {};
    const humanType = requestValues.landlord ? "landlord" : requestValues.tenant ? "tenant" : "all";
    let haveErrors = false;

    // Проверяем, какие поля остались пустыми
    selectedContractData.data.forEach((fieldsData) => {
      if (humanType === fieldsData.block_type || humanType === "all") {
        fieldsData.data.forEach((field) => {
          // Проверяем, если поле не является чекбоксом и его значение пустое
          if (
            (field.type !== "checkbox" || field.type !== "dynamic") &&
            field.required &&
            !inputValues[field.id]
          ) {
            newErrors[field.id] = true; // Если поле пустое, отмечаем его как ошибку
            haveErrors = true;
          }

          // Проверяем, если есть вложенные поля
          if (field.innerFields) {
            field.innerFields.forEach((innerField) => {
              // Проверяем, если вложенное поле не является чекбоксом и его значение пустое
              if (
                (field.type !== "checkbox" || field.type !== "dynamic") &&
                field.required &&
                !inputValues[innerField.id]
              ) {
                newErrors[innerField.id] = true; // Если поле пустое, отмечаем его как ошибку
                haveErrors = true;
              }
            });
          }
        });
      }
    });
    const data = {
      ...inputValues,
      ...requestValues,
    };
    console.log(JSON.stringify(data));
    setValuesError(newErrors);
    console.log(newErrors);
    haveErrors = false;
    if (!haveErrors) {
      try {
        setLoading(true);
        await generateDoc({ ...data }, setUrlDoc, setOrderId);
        istg && tg.close();
        setLoading(false);
      } catch (error) {
        setLoading(false);
        ModalDialog("Ошибка", `Ошибка формирования документа /n ${error.message}`, "error");
      }
      //setInputValues({});
      //setValuesError({});
    }
  };

  return (
    <ThemeProvider theme={theme}>
      {/* CssBaseline сбрасывает стандартные стили и применяет тему к body */}
      <CssBaseline />

      {/* Дополнительные глобальные стили для body */}
      <GlobalStyles
        styles={{
          body: {
            margin: 0, // Убираем отступы по умолчанию
            padding: 0, // Убираем внутренние отступы
            backgroundColor: theme.palette.background.default, // Применяем цвет фона из темы
            color: theme.palette.text.primary, // Применяем цвет текста из темы
            fontFamily: theme.typography.fontFamily, // Применяем шрифты из темы
          },
        }}
      />
      {isOrder ? (
        <>
          {!istg && (
            <>
              <ConHeader theme={theme} userData={userData} />
              <ConPaper theme={theme} />
            </>
          )}
          <Box>
            {!isType && (
              <TypeSelect
                selectedContract={selectedContract}
                onContractChange={handleContractChange}
              />
            )}
            {selectedContractData && (
              <ConDataInput
                selectedContractData={selectedContractData}
                inputValues={inputValues}
                setInputValues={setInputValues}
                valuesError={valuesError}
                setValuesError={setValuesError}
                handleSubmitValues={handleSubmitValues}
                requestValues={requestValues}
                loading={loading}
              />
            )}
          </Box>
        </>
      ) : (
        <Box textAlign="center">
          <Typography variant="h5" sx={{ padding: "50px" }}>
            Заказ не может быть изменен
          </Typography>
        </Box>
      )}
      {urlDoc && (
        <ConDialogDoc
          googleDocsUrl={googleDocsUrl}
          setUrlDoc={setUrlDoc}
          setPaymentId={setPaymentId}
          selectedContract={selectedContractData.name}
          orderId={orderId}
          setLoading={setLoading}
          loading={loading}
        />
      )}
    </ThemeProvider>
  );
};

export default HomePage;
