import axios from "axios";
import ModalDialog from "../components/ModalDialog/ModalDialog";

const apiUrl = process.env.REACT_APP_SERVICE_API;

const checkOrderExists = async (chatId, orderId) => {
  try {
    console.log ("chatId, orderId", chatId, orderId)
    const response = await axios.get(`${apiUrl}/get-order-redis`, {
      params: { chatId: chatId, orderId: orderId },
    });
    console.log(response.data);
    return response.data;
  } catch (error) {
    ModalDialog("Ошибка проверки заказа", error.message, "error");
    return false;
  }
};

export default checkOrderExists;
