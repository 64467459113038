import { useEffect, useState } from "react";
import SignaturePad from "../components/SignaturePad/SignaturePad";

const Signature = () => {
  const [isOpenSign, setIsOpenSign] = useState(true);
  const [params, setParams] = useState({});
  const [tg, setTg] = useState({});
  const [istg, setIsTg] = useState(false); // Состояние миниприложения в ТГ

  useEffect(()=> {
    if (window.Telegram && window.Telegram.WebApp) {
      const tg = window.Telegram.WebApp;
      setTg(tg);
      tg.ready();
      const initialTheme = tg.themeParams;
      if (!(initialTheme && Object.keys(initialTheme).length === 0)) {
        setIsTg(true);
      }
    }
  }, []);

  const handleClose = (e) => {
    tg.close();
    setIsOpenSign(false);
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    setParams({ humanType: params.get("humanType"), orderId: params.get("order_id"), chatId: params.get("chat_id") });
  }, []);

  return <SignaturePad isOpen={isOpenSign} hanldeClose={handleClose} params={params} tg={tg}/>;
};

export default Signature;
