import axios from "axios";

const apiUrl = process.env.REACT_APP_SERVICE_API;

const signDocument = async (imageData, humanType, orderId, chatId) => {
  try {
    const response = await axios.post(
      `${apiUrl}/sign-document`,
      {
        image: imageData,
        humanType: humanType,
        orderId: orderId,
        chatId: chatId,
      },
      {
        headers: {
          "Content-Type": "application/json", // Указываем, что передаем JSON
        },
      }
    );

    if (response.status === 200) {
      console.log("Подпись успешно сохранена на сервере");
      // Обработать успешный ответ от сервера
    }
  } catch (error) {
    console.error("Ошибка при сохранении подписи:", error);
  }
};

export default signDocument;
