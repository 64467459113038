import { FormControl, InputLabel, Select, MenuItem, Box, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import style from "./Fields.module.css";
import LightToolTip from "../LightToolTip/LightToolTip.jsx";

const SelectField = (props) => {
  const {
    id,
    label,
    value,
    values,
    data,
    selectedId,
    defaultValue,
    setValue,
    innerFields,
    tooltip,
  } = props;
  
  useEffect(() => {
    if (defaultValue) {
      setValue((prevValues) => ({
        ...prevValues,
        [id]: defaultValue,
      }));
    }
  }, [defaultValue]);

  const handleChange = (id, event) => {
    const value = event.target.value;
    if (value !== selectedId) {
      setValue((prevValues) => {
        const filteredValues = Object.fromEntries(
          Object.entries(prevValues).filter(
            ([key]) => !innerFields.some((field) => field.id === key)
          )
        );
        return { ...filteredValues };
      });
    }
    setValue((prevValues) => ({
      ...prevValues,
      [id]: value,
    }));
  };

  // const handleChangeInner = (id, event) => {
  //   const value = event.target.value;
  //   setInnerValue(value);
  //   setValue((prevValues) => ({
  //     ...prevValues,
  //     [id]: value,
  //   }));
  // };

  return (
    <Box>
      <Box className={style.boxItem}>
        <FormControl fullWidth>
          <InputLabel>{label}</InputLabel>
          <Select
            label={label}
            value={values[id] || ""}
            onChange={(event) => handleChange(id, event)}
            fullWidth
            sx={{ overflow: "visible" }}
          >
            {data.map((select) => (
              <MenuItem key={select.title} value={select.title} fullWidth>
                {select.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Box>{tooltip && <LightToolTip tooltip={tooltip} />}</Box>
      </Box>
      {value === selectedId &&
        innerFields.map((fields) => {
          return (
            <Box key={`Box_${fields.id}`} fullWidth sx={{ marginTop: "20px" }}>
              <TextField
                key={fields.id}
                label={fields.label}
                type={fields.type}
                inputMode={fields.inputMode}
                variant="outlined"
                value={values[fields.id] || ""}
                onChange={(event) => handleChange(fields.id, event)}
                /*                 {...(required && {
                  error: !!error[fields.id],
                  helperText: !!error[fields.id] ? "Заполните поле" : "",
                })} */
                fullWidth
              />
            </Box>
          );
        })}
    </Box>
  );
};

export default SelectField;
