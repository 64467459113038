import React, { useRef } from "react";
import SignatureCanvas from "react-signature-canvas";
import {
  Button,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "./SignaturePad.css";
import signDocument from "../../service/saveSignature.js";

const SignaturePad = ({ isOpen, hanldeClose, params, tg }) => {
  const sigCanvas = useRef({});

  // Функция для сохранения подписи в формате PNG
  const saveSign = async () => {
    try {
      const signatureData = sigCanvas.current.toDataURL("image/png");
      // Удаляем заголовок data:image/png;base64, из строки
      const base64Data = signatureData.replace(/^data:image\/png;base64,/, "");
      await signDocument(base64Data, params.humanType, params.orderId, params.chatId); // Сохранить изображение как URL
      tg.close();
    } catch (error) {
      console.log(error);
    }
  };

  // Функция для очистки канвы
  const clearSignature = () => {
    sigCanvas.current.clear();
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center", // Центрируем по горизонтали
        alignItems: "center", // Если нужно выровнять и по вертикали
        height: "100%", // Занимаем всю высоту экрана
        width: "100vw",
        overflow: "hidden",
        backgroundColor: "black",
      }}
    >
      <div
        style={{
          paddingBottom: "10px",
          paddingTop: "16px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100%", // Занимает всю высоту родителя
          width: "100%", // Занимает всю ширину родителя
        }}
      >
        <SignatureCanvas
          ref={sigCanvas}
          penColor="#0D47A1"
          minWidth={2} // Минимальная толщина линии
          maxWidth={5} // Максимальная толщина линии
          canvasProps={{
            style: {
              width: "700px", // Растягиваем canvas на всю ширину
              height: "300px", // Растягиваем canvas на всю высоту
            },
            className: "signatureCanvas",
          }}
        />
        <div>
          <Typography sx={{ marginTop: "10px", color: "white" }}>
            Сделайте подпись, чтобы она занимала максимальную площадь в окне выше и не была слишком
            маленькой
          </Typography>
        </div>
        <div style={{ display: "flex"}}>
          <Button variant="contained" onClick={saveSign} sx={{margin: "10px"}}>
            Подписать
          </Button>
          <Button variant="outlined" onClick={clearSignature} sx={{margin: "10px"}}>
            Очистить
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SignaturePad;
