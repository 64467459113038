import axios from "axios";
import ModalDialog from "../components/ModalDialog/ModalDialog";
import checkOrderExists from "./checkOrderExists";

const apiUrl = process.env.REACT_APP_SERVICE_API;

const getOrderData = async (key, orderId, humanType, setValues, setIsOrder) => {
  try {
    const response = await axios.get(`${apiUrl}/get-order-data`, {
      params: { orderId: orderId },
    });
    console.log(response.data);
    const isOrder = await checkOrderExists(key, orderId);
    if (isOrder) {
      humanType === "landlord" &&
        response.data?.landlord &&
        setValues({ ...response.data.landlord });
      humanType === "tenant" && response.data?.tenant && setValues({ ...response.data.tenant });
      humanType === "all" &&
        response.data?.landlord &&
        response.data?.tenant &&
        setValues({ ...response.data.landlord, ...response.data.tenant });
      setIsOrder(true);
    } else {
      setIsOrder(false);
    }
  } catch (error) {
    ModalDialog("Ошибка получения данных по заказу", error.message, "error");
  }
};

export default getOrderData;
